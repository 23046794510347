const contactPeopleBlocks = document.querySelectorAll('.contact-people .section');
const contactPeopleTitleMobile = document.querySelectorAll('.contact-people .title-mobile');

if(contactPeopleBlocks.length > 0) {
    const countryCode = sessionStorage.getItem('countryCode');
    if(!countryCode) {
        orderBlocksBasedOnCountry('no');
    } else {
        orderBlocksBasedOnCountry(countryCode);
    }
}

function orderBlocksBasedOnCountry(countryCode) {
    contactPeopleBlocks.forEach((block, i) => {
        let countries = block.dataset.order.split(',');
        if(countries.includes(countryCode)) {
            block.classList.add('first-order');
            contactPeopleTitleMobile[i].classList.add("show-mobile");
        }
    });
};