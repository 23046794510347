if ('IntersectionObserver' in window) {

    var options = {
        root: null, // relative to document viewport
        rootMargin: '0px 0px 400px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0 // visible amount of item shown in relation to root
    };

    var onIntersectionChange = function (changes, observer) {
        changes.forEach(change => {
            if (change.intersectionRatio > 0) {
                loadTarget(change.target);
            }
        });
    };

    var observer = new IntersectionObserver(onIntersectionChange, options);

    function setup() {
        var videos = document.querySelectorAll('video.lazy');
        videos.forEach(element => observer.observe(element));
    }

    setup();

    setTimeout(setup, 1000);
    setTimeout(setup, 3000);
} else {
    var lazyVideos = document.getElementsByClassName('lazy');
    for (var i = 0; i < lazyVideos.length; i++) {
        var img = lazyVideos[i];
        loadTarget(img);
    }
}


function loadTarget(target) {
    if (target.classList && !target.classList.contains('lazy')) {
        return;
    }

    if (target.getElementsByClassName('source').length === 0) {
        const source = createSourceElement(target.getAttribute('data-src'));
        target.appendChild(source);

        if (target.classList && target.classList.remove) {
            target.classList.remove('lazy');
        }

        showElement(target);
    } else {
        showElement(target);

        if (target.classList && target.classList.remove) {
            target.classList.remove('lazy');
        }
    }
}

function createSourceElement(src) {
    const source = document.createElement('source');
    source.type = 'video/mp4';
    source.src = src;
    return source;
}

function showElement(element) {
    if (element.classList) {
        if (!element.classList.contains('show')) {
            element.classList.add('show');
        }
    } else {
        element.className += ' show';
    }
}
