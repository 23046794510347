import { intersectionObserve } from './_intersection-observer';

let sections = document.querySelectorAll('#content-section section');
const hero = sections[0];
const header = document.querySelector('header');
const submenu = document.querySelector('.submenu');
const wrapper = document.querySelector('.submenu .wrapper');
const dropdowns = document.querySelectorAll('.current_page_item ul');
const dropdown = dropdowns[1];

const currentPage = document.querySelector('.current-page');

//Normal open close of menu

if (submenu) {
  const button = document.querySelector('.submenu .menu-button');
  const navBar = document.querySelector('.submenu .menu-content');
  const body = document.querySelector('body');
  const parent = document.querySelectorAll('.submenu .current-menu-item >a');

  if (button) {
    button.onclick = function toggleMenu() {
      var elDistanceToTop = navBar.getBoundingClientRect().top;
      console.log(elDistanceToTop);
      if (navBar.matches('.show')) {
        body.classList.remove('no-scroll');
        navBar.classList.remove('anim');
        submenu.style.transform = 'translateY(0px)';
        // submenu.style.opacity = 0;
        setTimeout(function () {
          navBar.classList.remove('show');

          // submenu.style.opacity = 1;
          // submenu.style.position = 'sticky';
        }, 500);
        submenu.querySelector('.wrapper').classList.remove('anim');
        button.classList.remove('menu-close');
        submenu.classList.remove('show-menu');
      } else {
        console.log(elDistanceToTop);
        // submenu.style.opacity = 0;
        submenu.style.transform =
          'translateY(-' + (elDistanceToTop - 46) + 'px)';
        // setTimeout(() => {
        //   submenu.style.position = 'fixed';
        //   submenu.style.opacity = 1;
        // }, 300);
        // submenu.style.display = 'block';

        body.classList.add('no-scroll');
        navBar.classList.add('show');
        navBar.classList.add('anim');
        button.classList.add('menu-close');

        submenu.classList.add('show-menu');

        submenu.querySelector('.wrapper').classList.add('anim');

        // Hides dropdown when menu is hidden
        parent.forEach(function (ele) {
          ele.classList.remove('show-ul');
        });
      }
    };
  }

  const breakpoint = 900;
  const addMargin = header.offsetHeight;

  if (currentPage) {
    if ($(window).width() < breakpoint) {
      currentPage.style.display = 'block';
      currentPage.style.marginTop = addMargin + 'px';

      setTimeout(() => {
        currentPage.classList.add('animate');
      }, 100);
    }
  }
  var lastScroll = 0;

  $(window).one('scroll', function () {
    const navBar = document.querySelector('.submenu .menu-content');
    $(window).scroll(function (e) {
      var timer = null;
      if (timer !== null) {
        clearTimeout(timer);
      }
      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (window.innerWidth < 900) {
        if (hero.offsetHeight > currentScroll) {
          lastScroll = currentScroll;

          setTimeout(() => {
            currentPage.classList.remove('animate');

            setTimeout(() => {
              currentPage.classList.remove('sticky');
              currentPage.style.display = 'none';
            }, 500);
          }, 10);
        }
      }
      if (!submenu.classList.contains('first-time')) {
        if (hero.offsetHeight - 900 < currentScroll) {
          submenu.classList.add('show');

          setTimeout(() => {
            submenu.classList.add('first-time');
          }, 500);
        }
      }

      const distance = 1200;
      if (distance < currentScroll) {
        if (currentScroll > 0 && lastScroll <= currentScroll) {
          //scrolling down

          lastScroll = currentScroll;
          if (
            submenu.classList.contains('show') ||
            currentPage.classList.contains('animate')
          ) {
            if (!navBar.classList.contains('show')) {
              setTimeout(() => {
                submenu.classList.remove('show');
                submenu.classList.add('hide');
              }, 20);

              if (window.innerWidth < 900) {
                setTimeout(() => {
                  currentPage.classList.remove('animate');
                }, 10);
              }
            }
          }
        } else {
          //scrolling up

          lastScroll = currentScroll;
          if (
            !submenu.classList.contains('show') ||
            !currentPage.classList.contains('animate')
          ) {
          }

          submenu.classList.add('hide');
          submenu.classList.add('show');

          if (window.innerWidth < 900) {
            currentPage.style.display = 'block';
            currentPage.style.marginTop = '0px';
            currentPage.style.top = submenu.offsetHeight + 'px';
            setTimeout(() => {
              currentPage.classList.add('animate');
              currentPage.classList.add('sticky');
            }, 10);
          }
        }
      }
    });
  });
}
