//Close all sections
const $allAccorionItems = $(
	".block-find-dealer-accordion .accordion-item .accordion-wrapper"
);
$allAccorionItems.slideUp("fast");

//open/close accordion items
const buttons = document.querySelectorAll(
	".block-find-dealer-accordion .col .open"
);

if (buttons) {
	buttons.forEach((button) => {
		//accordion-wrapper
		const $this = $(button).next();

		button.onclick = () => {
			if (!button.classList.contains("glow")) {
				button.parentElement.parentElement.parentElement.scrollIntoView(
					{ behavior: "smooth" }
				);
			}

			const $openAccordions = $(
				".block-find-dealer-accordion .accordion-item .show"
			);

			if ($this.hasClass("show")) {
				$this.removeClass("show");
				$this.slideUp("slow");
				buttons.forEach((button) => {
					button.classList.remove("glow");
				});
			} else {
				$openAccordions.removeClass("show");
				$openAccordions.slideUp("slow");
				buttons.forEach((button) => {
					button.classList.remove("glow");
				});

				setTimeout(() => {
					$this.addClass("show");
					button.classList.add("glow");
					$this.slideDown("slow");
				}, 100);
			}
		};
	});
}
