import { intersectionObserve } from "./_intersection-observer";

window.addEventListener('load', () => {
    setTimeout(() => {
        const elements = document.querySelectorAll('footer .bottom-text');
        const cookieIcon = document.querySelector('#CookiebotWidget');
        if(cookieIcon) {
            intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
                (function(el) {
                    if (intersectionRatio > 0 && !cookieIcon.classList.contains('hidden')) {
                        cookieIcon.classList.add('hidden');
                        setTimeout(() => {
                            cookieIcon.classList.add('display-none');
                        }, 300);
                    } else {
                        cookieIcon.classList.remove('display-none');
                        setTimeout(() => {
                            cookieIcon.classList.remove('hidden');
                        }, 1);
                    }
                })(element);
            });
        }
    }, 2000);
});