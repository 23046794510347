let accordion = document.querySelector(".accordion-text-and-image");

if(accordion) {
    let items = accordion.querySelectorAll(".accordion");
    let content = accordion.querySelectorAll(".accordion-content");

    if(items.length > 0) {
        items.forEach((item, index) => {
            item.addEventListener('click', function(e){
                if(item.classList.contains('active')) {
                    item.classList.remove('active')
                    content[index].style.maxHeight = '0px';
                } else {
                    items.forEach((closeItem, i) => { 
                        closeItem.classList.remove('active')
                        content[i].style.maxHeight = '0px';
                    });

                    item.classList.add('active')
                    content[index].style.maxHeight = content[index].scrollHeight + 'px';
                }
            });
        });
    }

    let hash = window.location.hash.slice(1);
    if(hash) {
        items.forEach((item, index) => {
            if(item.id === hash) {                
                item.classList.add('active')
                content[index].style.maxHeight = content[index].scrollHeight + 'px';
            }
        });
    } else if(items && content.length > 0) {
        items[0].classList.add('active')
        content[0].style.maxHeight = content[0].scrollHeight + 'px';
    }
}