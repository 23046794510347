const blocks = document.querySelectorAll('.country-spesific');

if(blocks.length > 0) {
    const countryCode = sessionStorage.getItem('countryCode');
    if(!countryCode) {
        renderBlocksBasedOnCountry('no');
    } else {
        renderBlocksBasedOnCountry(countryCode);
    }
}

function renderBlocksBasedOnCountry(countryCode) {
    blocks.forEach(block => {
        let countries = block.dataset.displayFor.split(',');
        if(countries.includes(countryCode)) {
            block.classList.add('show-block');
        }
    });
};

window.renderBlocksBasedOnCountry = renderBlocksBasedOnCountry;