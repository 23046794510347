let productsDisplay = window.document.querySelector('.products-display');
let animationDelay = 300;

if(productsDisplay) {
    let staticImages = productsDisplay.querySelectorAll('.static-image');
    let hoverFields = productsDisplay.querySelectorAll('.hover-field');
    let hoverButtons = productsDisplay.querySelectorAll('.hover-button');

    hoverFields.forEach((field, index) => {
        field.addEventListener('mouseover', function() {
            hoverButtons[index].classList.add('active');
            staticImages[index].classList.add('hover');
            setTimeout(() => {  
                hoverButtons[index].style.width = hoverButtons[index].children[0].clientWidth+28+'px';
            }, animationDelay);
        });
        
        field.addEventListener('mouseout', function() {
            hoverButtons[index].classList.remove('active');
            staticImages[index].classList.remove('hover');
            hoverButtons[index].style.width = 32+'px';
        });

        if(window.innerWidth > 900) {
            window.addEventListener("mousemove", e => {
                let fieldPosition = e.target.getBoundingClientRect();
                clientX = e.clientX - fieldPosition.left;
                clientY = e.clientY - fieldPosition.top;
                if(hoverButtons[index].classList.contains('active')) {
                    hoverButtons[index].style.left = clientX+'px';
                    hoverButtons[index].style.top = clientY+'px';
                }
            });
        }
    });
}