const buttons = document.querySelectorAll('.hover-block .open-button');

const allSiblings = document.querySelectorAll('.hover-block .inner-wrapper');

const wrapper = document.querySelector('.hover-block .wrapper');

let max = 0;

if (allSiblings.length > 0) {
  allSiblings.forEach((element) => {
    if (element.offsetHeight > max) {
      max = element.offsetHeight;
    }

    element.style.width = window.innerWidth + 'px';
  });

  onresize = (event) => {
    allSiblings.forEach((allSibling) => {
      allSibling.style.width = window.innerWidth + 'px';
    });
  };

  allSiblings[0].style.display = 'grid';
  allSiblings[1].style.display = 'none';
}

if (wrapper) {
  if (window.innerWidth < 900) {
    wrapper.style.height = max + 'px';
  }

  onresize = (event) => {
    if (window.innerWidth < 900) {
      wrapper.style.height = max + 'px';
    }
  };
}

if (buttons) {
  buttons.forEach((button) => {
    const sibling = button.nextElementSibling;
    //   console.log(sibling);
    function openPage() {
      if (!button.classList.contains('open')) {
        button.classList.add('open');

        buttons.forEach((allButtons) => {
          if (allButtons != button) {
            allButtons.classList.remove('open');
          }
        });
      }

      if (!sibling.classList.contains('open')) {
        sibling.style.display = 'grid';
        setTimeout(() => {
          sibling.classList.add('open');
        }, 10);

        allSiblings.forEach((allSibling) => {
          if (allSibling != sibling) {
            allSibling.classList.remove('open');
            setTimeout(() => {
              allSibling.style.display = 'none';
            }, 500);
          }
        });
      }
    }
    button.onclick = () => {
      openPage();
    };

    button.onmouseover = () => {
      openPage();
    };
  });
}
