const partnerForm = document.querySelector('.partner-form-wrapper');

if (partnerForm) {
  const formButton = document.querySelectorAll('.partner-button');
  const formContainer = partnerForm.querySelector('.partner-form-container');
  const exit = partnerForm.querySelectorAll('.backdrop, .form-exit');
  const body = document.querySelector('body');
  formButton.forEach((button) => {
    button.addEventListener('click', function (e) {
      partnerForm.classList.add('display');
      setTimeout(function () {
        partnerForm.classList.add('active');
        formContainer.classList.add('active');
        body.style.overflow = 'hidden';
      }, 1);
    });
  });

  exit.forEach((element) => {
    element.addEventListener('click', function (e) {
      body.style.overflow = 'visible';
      partnerForm.classList.remove('active');
      formContainer.classList.remove('active');
      setTimeout(function () {
        partnerForm.classList.remove('display');
      }, 300);
    });
  });
}
