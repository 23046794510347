import { intersectionObserve } from "./_intersection-observer";
let submenu = document.querySelector(".product-submenu");

if(submenu) {
    const elements = document.querySelectorAll('.submenu-trigger-element');
    let subLinksTrigger = submenu.querySelector('.current-menu-item');
    let subLinks = document.querySelector('.sub-links');
    let wrapper = submenu.querySelector('.wrapper');
    let lastScrollTop = 0;
    
    // Set height of submenu wrapper
    let wrapperHeight = 80;
    wrapper.style.height = wrapperHeight + 'px';

    intersectionObserve(elements, -400, 0, (element, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !submenu.classList.contains('show-sub-links')) {
                submenu.classList.add('show-sub-links');
                setSubmenuHeight(wrapper, wrapperHeight, subLinks);
            } else {
                submenu.classList.remove('show-sub-links');
                wrapper.style.height = wrapperHeight + 'px';
            }
        })(element);
    });

    window.onscroll = function(){
        if(window.scrollY > window.innerHeight + 400) {
            let st = window.scrollY || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                submenu.classList.remove('show-sub-links');
                wrapper.style.height = wrapperHeight + 'px';
            } else if (st < lastScrollTop) {
                submenu.classList.add('show-sub-links');
                setSubmenuHeight(wrapper, wrapperHeight, subLinks);
            } 
            lastScrollTop = st <= 0 ? 0 : st;
        }
    }

    if(subLinksTrigger) {
        subLinksTrigger.addEventListener('mouseover', function(e) {
            submenu.classList.add('show-sub-links');
            setSubmenuHeight(wrapper, wrapperHeight, subLinks);
        });

        subLinksTrigger.addEventListener('mouseout', function(e) {
            submenu.classList.remove('show-sub-links');
            wrapper.style.height = wrapperHeight + 'px';
        });
    }
}

function setSubmenuHeight(wrapper, wrapperHeight, subLinks) {
    wrapper.style.height = wrapperHeight + subLinks.clientHeight + 'px';
}