const $heroAnimationText = $(".split-text").add(".cta");

if($heroAnimationText.length > 0) {
    const $containerHeight = $(".amina-s-hero-animation").outerHeight();
    const $itemAmount = $heroAnimationText.length;
    const $splitHeight = $containerHeight / $itemAmount;

    $( document ).on( "scroll", function() {
        let scrollY = window.scrollY;
        if(scrollY < $containerHeight) {
            $heroAnimationText.each(function( i ) {
                const $this = $( this );
                const index = i + 1;
                const $scrollTarget = $splitHeight * i / 3;
                if(i === 0) {
                    if(scrollY > 0) {
                        $this.addClass("show");
                    }
                } else {
                    if(scrollY > $scrollTarget) {
                        $this.addClass("show");
                    }
                }
            });
        }
    } );
}