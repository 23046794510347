let contact = document.querySelector(".contact-page");

if(contact) {
    let items = contact.querySelectorAll(".item");
    let answers = contact.querySelectorAll(".answer");
    let section = contact.querySelectorAll('.section');
    let stickyLinks = contact.querySelectorAll('.sticky a');

    for(let i=0; i<items.length; i++) {
        items[i].addEventListener('click', function(e){
            if(items[i].classList.contains('active')) {
                items[i].classList.remove('active')
                answers[i].style.maxHeight = '0px';
            } else {
                items[i].classList.add('active')
                answers[i].style.maxHeight = answers[i].scrollHeight + 'px';
            }
        });
    }

    document.addEventListener("scroll", (event) => {
        var current = "";
        
        section.forEach((item) => {
            if (item.getBoundingClientRect().top <= 250 && item.getBoundingClientRect().top != 0) {
              current = item.getAttribute("id"); 
            }
        });

        stickyLinks.forEach((link) => {
            link.classList.remove("active");
            if (link.classList.contains(current)) {
                link.classList.add("active");
            }
        });
    });
}