import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

addEventListener("load", (event) => {
  let lastWindowSize = window.innerWidth;
  const canvas = document.getElementById("hero-amina-s-charger");
  const canvasMobile = document.getElementById("hero-amina-s-charger-mobile");

  if(canvas || canvasMobile) {
    const context = canvas.getContext("2d");
    const contextMobile = canvasMobile.getContext("2d");

    if (window.innerWidth > 900) {
      let currentFrame;
      canvas.width = 1800;
      canvas.height = 1013;
      currentFrame = index => (
        `/wp-content/themes/grensesnitt/static/images/amina-s-hero-animation/${(index + 1).toString().padStart(4, '0')}.png`
      );
      const frameCount = 114;
      const images = []
      const charger = {
        frame: 0
      };
      
      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      gsap.to(charger, {
          frame: frameCount - 1,
          snap: "frame",
          ease: "none",
          scrollTrigger: {
            scrub: 0.1,
            start: "0px top",
            end: "+=700",
            trigger: ".amina-s-hero-animation",
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
      }); 
      
      images[0].onload = render;
      
      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[charger.frame], 0, 0); 
      }
    } else {
      let currentFrame;
      canvasMobile.width = 800;
      canvasMobile.height = 450;
      currentFrame = index => (
        `/wp-content/themes/grensesnitt/static/images/amina-s-hero-animation/mobile/Amina-S-web-Hero-01_0${(index + 1).toString().padStart(4, '0')}.png`
      );

      const frameCount = 114;
      const images = []
      const charger = {
        frame: 0
      };
      
      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      gsap.to(charger, {
        frame: frameCount - 1,
        snap: "frame",
        ease: "none",
        scrollTrigger: {
          scrub: 0.1,
          start: "0px top",
          end: "+=700",
          trigger: ".amina-s-hero-animation",
        },
        onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
      });   
      
      images[0].onload = render;
      
      function render() {
        contextMobile.clearRect(0, 0, canvasMobile.width, canvasMobile.height);
        contextMobile.drawImage(images[charger.frame], 0, 0); 
      }
    }  

    $(window).resize(function(e) {
      if ((window.innerWidth >= 900) && (lastWindowSize < 900)) {
        lastWindowSize = window.innerWidth;
  
        let currentFrame;
        canvas.width = 1800;
        canvas.height = 1013;
        currentFrame = index => (
          `/wp-content/themes/grensesnitt/static/images/amina-s-hero-animation/${(index + 1).toString().padStart(4, '0')}.png`
        );
        const frameCount = 114;
        const images = []
        const charger = {
          frame: 0
        };
        
        for (let i = 0; i < frameCount; i++) {
          const img = new Image();
          img.src = currentFrame(i);
          images.push(img);
        }
  
        gsap.to(charger, {
          frame: frameCount - 1,
          snap: "frame",
          ease: "none",
          scrollTrigger: {
            scrub: 0.1,
            start: "0px top",
            end: "+=700",
            trigger: ".amina-s-hero-animation",
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
        });   
        
        images[0].onload = render;
        
        function render() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[charger.frame], 0, 0); 
        }
      } else if((window.innerWidth < 900) && (lastWindowSize > 900)) {
        lastWindowSize = window.innerWidth;
  
        let currentFrame;
        canvasMobile.width = 800;
        canvasMobile.height = 450;
        currentFrame = index => (
          `/wp-content/themes/grensesnitt/static/images/amina-s-hero-animation/mobile/Amina-S-web-Hero-01_0${(index + 1).toString().padStart(4, '0')}.png`
        );
  
        const frameCount = 114;
        const images = []
        const charger = {
          frame: 0
        };
        
        for (let i = 0; i < frameCount; i++) {
          const img = new Image();
          img.src = currentFrame(i);
          images.push(img);
        }
  
        gsap.to(charger, {
          frame: frameCount - 1,
          snap: "frame",
          ease: "none",
          scrollTrigger: {
            scrub: 0.1,
            start: "0px top",
            end: "+=700",
            trigger: ".amina-s-hero-animation",
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
        }); 
        
        images[0].onload = render;
        
        function render() {
          contextMobile.clearRect(0, 0, canvasMobile.width, canvasMobile.height);
          contextMobile.drawImage(images[charger.frame], 0, 0); 
        }
      }
    });
  }
});