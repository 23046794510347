let faq = document.querySelector(".archive-list");

if(faq) {
    let items = faq.querySelectorAll(".item");
    let answers = faq.querySelectorAll(".answer");
    let section = faq.querySelectorAll('.section');
    let stickyLinks = faq.querySelectorAll('.sticky a');

    items.forEach((item, index) => {
        item.addEventListener('click', function(e){
            if(item.classList.contains('active')) {
                item.classList.remove('active')
                answers[index].style.maxHeight = '0px';
            } else {
                items.forEach((item, index) => {
                    item.classList.remove('active')
                    answers[index].style.maxHeight = '0px';
                });

                item.classList.add('active')
                answers[index].style.maxHeight = answers[index].scrollHeight + 12 + 'px';
            }
        });
    });

    document.addEventListener("scroll", (event) => {
        var current = "";
        
        section.forEach((item) => {
            if (item.getBoundingClientRect().top <= 250 && item.getBoundingClientRect().top != 0) {
              current = item.getAttribute("id"); 
            }
        });

        stickyLinks.forEach((link) => {
            link.classList.remove("active");
            if (link.classList.contains(current)) {
                link.classList.add("active");
            }
        });
    });
}