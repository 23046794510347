import Glide from "@glidejs/glide";
let glide = null;

function initThreeColFeatures(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        bound: true,
        startAt: 0,
        gap: 16,
        perView: 3,
        breakpoints: {
            900: {
                perView: 1,
                gap: 16,
                peak: 16
            }            
        }
    });
    
    glide.mount();

    window.addEventListener('resize', function() {
        if(window.innerWidth > 900) {
            glide.disable();
        } else {
            glide.enable();
        }
    });

    window.addEventListener('load', function() {
        if(window.innerWidth > 900) {
            glide.disable();
        }
    });
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

document.querySelectorAll('.feature-slider-container .glide').forEach((glide, index) => {
    initThreeColFeatures(glide.getAttribute('id'));
});

window.initThreeColFeatures = initThreeColFeatures;