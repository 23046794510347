// Menu on scroll
import { intersectionObserve } from "./_intersection-observer";
const breakpoint = 1440;
const header = document.querySelector("header");
const submenu = document.querySelector(".product-submenu");

if (header && !submenu) {
	window.addEventListener("load", (event) => {
		const hero = document.querySelector(
			".subpage-hero, .amina-s-hero-animation"
		);
		const gradientBackground = document.querySelector(
			"#content-section.orange-gradient"
		);

		if (hero) {
			let scrollPosition = window.scrollY;
			let heroHeight = hero.offsetHeight;

			window.addEventListener("scroll", function () {
				scrollPosition = window.scrollY;
				if (heroHeight < scrollPosition) {
					header.classList.add("background");
				} else {
					header.classList.remove("background");
				}
			});
		} else if (gradientBackground) {
			header.classList.add("background-plain");
		} else {
			header.classList.add("background-plain");
		}
	});
}

// hamburger nav
if (window.innerWidth < breakpoint) {
	window.addEventListener("load", (event) => {
		const button = document.querySelector(".menu-button");
		const navBar = document.querySelector("header .menu-content");
		const header = document.querySelector("header");
		const body = document.querySelector("body");
		const html = document.querySelector("html");
		const parent = document.querySelectorAll(".menu-item-has-children >a");
		const weglot = document.querySelector("#weglot-switcher-2");

		if (weglot) {
			weglot.classList.add("weglot-invert");
		}

		button.onclick = function toggleMenu() {
			if (navBar.matches(".show")) {
				body.classList.remove("no-scroll");
				html.style.overflow = "visible";
				navBar.classList.remove("anim");
				setTimeout(function () {
					navBar.classList.remove("show");
				}, 500);
				header.querySelector(".wrapper").classList.remove("anim");
				button.classList.remove("menu-close");
				header.classList.remove("show-menu");

				if (weglot) {
					weglot.style.display = "none";
				}
			} else {
				body.classList.add("no-scroll");
				html.style.overflow = "hidden";
				navBar.classList.add("show");
				navBar.classList.add("anim");
				button.classList.add("menu-close");
				header.classList.add("show-menu");
				header.querySelector(".wrapper").classList.add("anim");
				if (weglot) {
					weglot.style.display = "inline-block";
				}

				// Hides dropdown when menu is hidden
				parent.forEach(function (ele) {
					ele.classList.remove("show-ul");
				});
			}
		};

		//dropdown
		/**
		 * check if parent has child with classname
		 * on click add or remove class
		 */
		if (window.innerWidth > 900) {
			parent.forEach(function (ele) {
				ele.addEventListener("click", function (e) {
					e.preventDefault();
					if (ele.parentNode.classList.contains("show-ul")) {
						window.location = e.target.href;
					} else {
						ele.parentNode.classList.add("show-ul");
					}
				});
			});
		}
		// arrow.forEach(function (ele) {
		//   ele.addEventListener('click', function (e) {
		//     e.preventDefault();
		//     if (ele.parentNode.classList.contains('show-ul')) {
		//       ele.parentNode.classList.remove('show-ul');
		//     } else {
		//       ele.parentNode.classList.add('show-ul');
		//     }
		//   });
		// });
	});
} else {
	const links = document.querySelectorAll(".menu-item-has-children >a");
	const descendants = document.querySelectorAll(
		" .menu-item-has-children > ul > li > a"
	);

	descendants.forEach((descendant) => {
		descendant.addEventListener("focus", (event) => {
			const grandParent =
				descendant.parentElement.parentElement.parentElement;
			grandParent.classList.add("show-ul");
		});

		descendant.addEventListener("blur", (event) => {
			const grandParent =
				descendant.parentElement.parentElement.parentElement;
			grandParent.classList.remove("show-ul");
		});
	});

	links.forEach((link) => {
		link.addEventListener("focus", (event) => {
			link.parentNode.classList.add("show-ul");
		});

		link.addEventListener("blur", (event) => {
			link.parentNode.classList.remove("show-ul");
		});
	});
}

//hide/show on scroll

if (!submenu && header) {
	header.classList.add("fixed");
	let lastScrollTop = 0;
	window.onscroll = function(){
		//Add class to header logo for animation
		let st = window.scrollY || document.documentElement.scrollTop;
		if (st > lastScrollTop) {
			header.classList.add("scroll-down");
		} else if (st < lastScrollTop) {
			header.classList.remove("scroll-down");
		} 
		lastScrollTop = st <= 0 ? 0 : st;
	}
}

