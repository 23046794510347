let featureContainer = document.querySelectorAll('.feature-slider-container');
let animationDelay = 400;

if(featureContainer.length > 0) {
    featureContainer.forEach((feature, index) => {
        let hoverFields = feature.querySelectorAll('.hover-field');
        let hoverButtons = feature.querySelectorAll('.hover-button');
        
        hoverFields.forEach((field, index) => {
            field.addEventListener('mouseover', function() {
                hoverButtons[index].classList.add('active');

                setTimeout(() => {  
                    hoverButtons[index].style.width = hoverButtons[index].children[0].clientWidth+28+'px';
                }, animationDelay);
            });
            
            field.addEventListener('mouseout', function() {
                hoverButtons[index].classList.remove('active');
                hoverButtons[index].style.width = 32+'px';
            });

            if(window.innerWidth > 900) {
                window.addEventListener("mousemove", e => {
                    let fieldPosition = e.target.getBoundingClientRect();
                    clientX = e.clientX - fieldPosition.left;
                    clientY = e.clientY - fieldPosition.top;
                    if(hoverButtons[index].classList.contains('active')) {
                        hoverButtons[index].style.left = clientX+'px';
                        hoverButtons[index].style.top = clientY+'px';
                    }
                });
            }
        });
    });
}