let accordionBlock = document.querySelectorAll('.find-dealer-country, .smart-accordion');
let prevActive = null;

if(accordionBlock.length > 0) {
    accordionBlock.forEach(block => {
        let categories = block.querySelectorAll('.accordion-wrapper');
        categories.forEach(category => {
            category.addEventListener('click', function() {
                let container = category.querySelector('.item-container');

                if(prevActive && prevActive !== category) {
                    let prevContainer = prevActive.querySelector('.item-container');
                    prevActive.classList.remove('active');
                    prevContainer.classList.remove('active');
                    closeCategory(prevContainer);
                }

                if(category.classList.contains('active')) {
                    category.classList.remove('active');
                    container.classList.remove('active');
                    closeCategory(container);
                } else {
                    let container = category.querySelector('.item-container');
                    category.classList.add('active');
                    container.classList.add('active')
                    openCategory(container);
                    
                    setTimeout(() => {
                        category.parentElement.scrollIntoView(
                            { behavior: "smooth" }
                        );
                    }, 100);
                }
                
                prevActive = category;
            });
        });
    });
}

function openCategory(category) {
    category.style.maxHeight = category.scrollHeight+'px';
}

function closeCategory(category) {
    category.style.maxHeight = 0;
}   