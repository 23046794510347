import { intersectionObserve } from "./_intersection-observer";


window.addEventListener('load', function() {

    this.setTimeout(() => {
        const elements = document.querySelectorAll('footer .footer-additional-links');
        const widget = document.querySelector('#mavenoid-shadow-root');
    
        intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
            (function(el) {
                if (intersectionRatio > 0) {
                    widget.parentElement.classList.add('hide');
                } else {
                    widget.parentElement.classList.remove('hide');
                }
            })(element);
        });
    }, 1000);
});