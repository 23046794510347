import glide from '@glidejs/glide';
import { moveGlide } from './_image-gallery-slider-configuration.js';

let ImageGallerySlider = document.querySelectorAll('.image-gallery');
let clientX = 0;
let clientY = 0;
let animationDelay = 300;

if(ImageGallerySlider.length > 0) {
    ImageGallerySlider.forEach((slider, index) => {
        let arrowPrev = slider.querySelector('.glide__arrows button.prev');
        let arrowNext = slider.querySelector('.glide__arrows button.next');
        let nextField = slider.querySelector('.cursor-next-field');
        let prevField = slider.querySelector('.cursor-prev-field');
        let textContainer = slider.querySelectorAll('.text-container');

        if(window.innerWidth > 900) {
            window.addEventListener("mousemove", e => {
                clientX = e.clientX;
                clientY = e.clientY;
                arrowNext.style.left = clientX+'px';
                arrowNext.style.top = clientY+'px';
                arrowPrev.style.left = clientX+'px';
                arrowPrev.style.top = clientY+'px';
            });
        }
    
        nextField.addEventListener("mouseover", e => {
            arrowNext.classList.add('active');
            setTimeout(() => {  
                arrowNext.style.width = arrowNext.children[0].clientWidth+28+'px';
            }, animationDelay);
        });
        
        nextField.addEventListener("mouseleave", e => {
            arrowNext.classList.remove('active');
            arrowNext.style.width = 32+'px';
        });
        
        nextField.addEventListener("click", e => {
            moveGlide('>');
        });
        
        prevField.addEventListener("mouseover", e => {
            arrowPrev.classList.add('active');
            setTimeout(() => {  
                arrowPrev.style.width = arrowPrev.children[0].clientWidth+28+'px';
            }, animationDelay);
        });

        prevField.addEventListener("mouseleave", e => {
            arrowPrev.classList.remove('active');
            arrowPrev.style.width = 32+'px';
        });

        prevField.addEventListener("click", e => {
            moveGlide('<');
        });

        arrowPrev.addEventListener("click", e => {
            moveGlide('<');
        });

        arrowNext.addEventListener("click", e => {
            moveGlide('>');
        });
        
        if(textContainer.length > 0) {
            let maxHeight = 0;
            textContainer.forEach((text, index) => {
                if(text.clientHeight > maxHeight) {
                    maxHeight = text.clientHeight;
                }
            });

            let glideContainer = slider.querySelector('.glide');
            glideContainer.style.height = glideContainer.clientHeight + maxHeight + 40 +'px';
            
            window.addEventListener("resize", e => {
                let maxHeight = 0;
                textContainer.forEach((text, index) => {
                    if(text.clientHeight > maxHeight) {
                        maxHeight = text.clientHeight;
                    }
                });
                let glideContainer = slider.querySelector('.glide');
                let glideTrack = slider.querySelector('.glide__track');
                glideContainer.style.height = glideTrack.clientHeight + maxHeight + 120 +'px';
            });
        }

    });
}