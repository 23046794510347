import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

addEventListener("load", (event) => {
    const canvas = document.getElementById("scroll-animation-features");

	if (!canvas) {
		return;
	}
	
	if ('IntersectionObserver' in window) {

		var options = {
			root: null, // relative to document viewport
			rootMargin: '0px 0px 400px', // margin around root. Values are similar to css property. Unitless values not allowed
			threshold: 0 // visible amount of item shown in relation to root
		};
	
		var onIntersectionChange = function (changes, observer) {
			changes.forEach(change => {
				if (change.intersectionRatio > 0) {
					loadAnimationFeatures(canvas);
				}
			});
		};
	
		var observer = new IntersectionObserver(onIntersectionChange, options);
	
		function setup() {
			observer.observe(canvas);
		}

		setup();

		setTimeout(setup, 1000);
		setTimeout(setup, 3000);
	} else {
		loadAnimationFeatures(canvas);
	}
});

let isAnimationFeatureLoaded = false;
function loadAnimationFeatures(canvas) {

	if (isAnimationFeatureLoaded) {
		return;
	}
    
	const context = canvas.getContext("2d");
	
	canvas.width = 1650;
	canvas.height = 928;
	
	const frameCount = 75;

	let chosenAnimation = canvas.dataset.animation;
	const currentFrame = index => (
	//   `http://aminacharging.test/wp-content/themes/grensesnitt/static/images/amina-s-collapse/Amina-S-charger_Exploded-02_${(index + 1).toString().padStart(4, '0')}.jpg`
		`/wp-content/themes/grensesnitt/static/images/${chosenAnimation}/${(index + 1).toString().padStart(4, '0')}.jpg`
	);
	
	const images = []
	const charger = {
		frame: 0
	};
	
	for (let i = 0; i < frameCount; i++) {
		const img = new Image();
		img.src = currentFrame(i);
		images.push(img);
	}
	
	// Transform explosion to implosion
	images.reverse();

	let sap = gsap.matchMedia();

	sap.add("(min-width: 900px)", () => {
		gsap.to(charger, {
			frame: frameCount - 1,
			snap: "frame",
			ease: "none",
			scrollTrigger: {
			scrub: 0.5,
			start: "-200px top",
			end: "+=800",
			trigger: ".scroll-amination-features",
			},
			onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
		});
	});

	sap.add("(max-width: 899px)", () => {
		gsap.to(charger, {
			frame: frameCount - 1,
			snap: "frame",
			ease: "none",
			scrollTrigger: {
			scrub: 0.5,
			start: "top center",
			end: "+=500",
			trigger: ".scroll-amination-features",
		},
			onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
		});
	});

	images[0].onload = render;
	
	function render() {
		context.clearRect(0, 0, canvas.width, canvas.height);
		context.drawImage(images[charger.frame], 0, 0); 
	}

	isAnimationFeatureLoaded = true;
}
